import { Tabs, Tab, Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";

import store from "app/store/store";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";

import Loading from "app/pages/home/components/Loading";
import {
  BarAndLineChartTestComponent,
  BarChartAverageTime,
  BarChartFaixaEtaria,
  BarChartGender,
  BarChartPorcentagem,
  BarChartShort,
  CardComponent
} from "components";

interface DataTest {
  [key: string]: any;
}

interface Colors {
  [key: string]: string;
}

interface Respostas {
  [key: string]: {
    Acertos: number;
    Erros: number;
    Total: number;
  };
}

interface RespostaDetalhada {
  RightAnswer: string;
  Acertos: number;
  Erros: number;
  AcertosPercent: string;
  ErrosPercent: string;
}

interface Resultado {
  name: string;
  value: number;
  fill: string;
}

interface AverageByGenre {
  genre: string;
  averageAcertos: number;
}

interface TestData {
  RightAnswer: string;
  Inicio_Teste: string;
  Fim_Teste: string;
}

interface TimeAverage {
  RightAnswer: string;
  averageTimeSeconds: number;
}

interface ShotsAverage {
  RightAnswer: string;
  averageShots: number;
}

interface NumeroTeste {
  [key: number]: number;
}

interface NumeroTesteProps {
  data: NumeroTeste;
}

interface DataItem {
  faixaEtaria: string;
  acertos: number;
}

interface AverageData {
  faixaEtaria: string;
  mediaAcertos: number;
}

function calcularMediaPorFaixaEtaria(data: DataTest[]): AverageData[] {
  const sums: { [faixa: string]: number } = {};
  const counts: { [faixa: string]: number } = {};

  data.forEach(item => {
    if (sums[item.faixaEtaria]) {
      sums[item.FaixaEtaria] += item.Acertos;
      counts[item.FaixaEtaria] += 1;
    } else {
      sums[item.FaixaEtaria] = item.Acertos;
      counts[item.FaixaEtaria] = 1;
    }
  });

  return Object.keys(sums).map(faixa => ({
    faixaEtaria: faixa,
    mediaAcertos: sums[faixa] / counts[faixa]
  }));
}

const calculateAverageTimeByAnswer = (data: DataTest[]): TimeAverage[] => {
  const timeSums: { [key: string]: number } = {};
  const counts: { [key: string]: number } = {};

  data.forEach(item => {
    const startTime = new Date(item.Inicio_Teste);
    const endTime = new Date(item.Fim_Teste);
    const timeDifference = (endTime.getTime() - startTime.getTime()) / 1000;

    if (timeSums[item.RightAnswer]) {
      timeSums[item.RightAnswer] += timeDifference;
      counts[item.RightAnswer] += 1;
    } else {
      timeSums[item.RightAnswer] = timeDifference;
      counts[item.RightAnswer] = 1;
    }
  });

  const averages: TimeAverage[] = Object.keys(timeSums).map(key => ({
    RightAnswer: key,
    averageTimeSeconds: timeSums[key] / counts[key]
  }));

  return averages;
};

const calculateAverageShotsByAnswer = (data: DataTest[]): ShotsAverage[] => {
  const shotsSums: { [key: string]: number } = {};
  const counts: { [key: string]: number } = {};

  data.forEach(item => {
    if (shotsSums[item.RightAnswer]) {
      shotsSums[item.RightAnswer] += item.Shots;
      counts[item.RightAnswer] += 1;
    } else {
      shotsSums[item.RightAnswer] = item.Shots;
      counts[item.RightAnswer] = 1;
    }
  });

  const averages: ShotsAverage[] = Object.keys(shotsSums).map(key => ({
    RightAnswer: key,
    averageShots: shotsSums[key] / counts[key]
  }));

  return averages;
};

const processData = (data: DataTest[]): RespostaDetalhada[] => {
  const results: Respostas = {};

  data.forEach(item => {
    const key = item.RightAnswer;
    if (!results[key]) {
      results[key] = { Acertos: 0, Erros: 0, Total: 0 };
    }
    if (item.Resultado === "Acerto") {
      results[key].Acertos += 1;
    } else {
      results[key].Erros += 1;
    }
    results[key].Total += 1;
  });

  return Object.keys(results).map(key => ({
    RightAnswer: key,
    Acertos: results[key].Acertos,
    Erros: results[key].Erros,
    AcertosPercent: ((results[key].Acertos / results[key].Total) * 100).toFixed(
      2
    ),
    ErrosPercent: ((results[key].Erros / results[key].Total) * 100).toFixed(2)
  }));
};

const DashboardResultados: React.FC = () => {
  const api = new AnanseApiService();
  const { auth } = store.getState();

  const [respostas, setRespostas] = useState<RespostaDetalhada[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Estado de carregamento
  const [averages, setAverages] = useState<Resultado[]>([]);
  const [timeAverages, setTimeAverages] = useState<TimeAverage[]>([]);
  const [shotsAverages, setShotsAverages] = useState<ShotsAverage[]>([]);
  const [testsAcertos, setTests] = useState<NumeroTeste>({});
  const [mediaFaixaEtaria, setMediaFaixaEtaria] = useState<AverageData[]>([]);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const sumByGenre: { [key: string]: number } = { M: 0, F: 0 };

  useEffect(() => {
    const HomemSet = new Set<string>();
    const MulherSet = new Set<string>();
    const fetchData = async () => {
      setIsLoading(true); // Começa o carregamento

      try {
        const response = await api.makeHttpRequest({
          url: `/dashboard/testdetalhesdashanduser?UserId=${auth.user.id}`
        });
        if (response && response.result && Array.isArray(response.result)) {
          const data: DataTest[] = response.result;
          const respostasLista = processData(data);
          data.forEach(item => {
            const valor = item.Acerto === 1 ? 1 : 0;
            sumByGenre[item.genre] += valor;
            if (item.genre === "M") {
              HomemSet.add(item.Paciente);
            } else {
              MulherSet.add(item.Paciente);
            }
          });
          const colors: Colors = {
            M: "#1e90ff",
            F: "#ff69b4"
          };
          const time = calculateAverageTimeByAnswer(data);
          const shots = calculateAverageShotsByAnswer(data);
          const media: Resultado[] = Object.keys(sumByGenre).map(genre => ({
            name: genre,
            value:
              sumByGenre[genre] /
              (genre === "M" ? HomemSet.size : MulherSet.size),
            fill: colors[genre] || "#888888"
          }));
          setRespostas(respostasLista);
          setAverages(media);
          setTimeAverages(time);
          setShotsAverages(shots);
          console.log(shotsAverages);
        } else {
          console.error("Unexpected response format:", response);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [auth.user.id]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Começa o carregamento
      try {
        const response = await api.makeHttpRequest({
          url: `/dashboard/testcabecalhodashanduser?UserId=${auth.user.id}`
        });
        if (response && response.result && Array.isArray(response.result)) {
          const lista = response.result;
          const data: DataTest[] = lista;
          const tests: NumeroTeste = {};
          data.forEach(item => {
            const test = item.Acertos;
            if (tests[test]) {
              tests[test] += 1;
            } else {
              tests[test] = 1;
            }
          });
          const listaFaixa = calcularMediaPorFaixaEtaria(data);
          console.log(listaFaixa);
          setMediaFaixaEtaria(listaFaixa);
          setTests(tests);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderTabContent = (index: number) => {
    switch (index) {
      case 0:
        return <BarChartPorcentagem data={respostas} />;
      case 1:
        return <BarChartGender data={averages} />;
      case 2:
        return <BarChartAverageTime data={timeAverages} />;
      case 3:
        return <BarChartShort data={shotsAverages} />;
      case 4:
        return <BarAndLineChartTestComponent data={testsAcertos} />;
      case 5:
        return <BarChartFaixaEtaria data={mediaFaixaEtaria} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
        centered
      >
        {cardTitles.map((title, index) => (
          <Tab key={index} label={title} />
        ))}
      </Tabs>
      <Box mt={2}>
        <CardComponent
          style={{ marginBottom: "30px" }}
          styleContent={{ height: `${windowHeight - 200}px` }}
        >
          {renderTabContent(selectedTab)}
        </CardComponent>
      </Box>
    </div>
  );
};

const cardTitles = [
  "Acertos por Fragrância",
  "Acertos por Gênero",
  "Tempo médio de resposta",
  "Média de Disparos",
  "Acertos por Testes",
  "Acertos por Faixa Etária"
];

export default DashboardResultados;
