import { ExpandLess, ExpandMore, Menu as MenuIcon } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Tooltip,
  useMediaQuery
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useTranslation } from "_metronic/i18n/language";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Brand from "../brand/Brand";
import { menuConfig } from "../core/";
import IconComponent from "./renderIcons";

const drawerWidth = 240;
const drawerWidthLeave = 70;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = theme => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

const AsideLeft = ({ onToggle, isMobileActive }) => {
  const theme = useTheme();
  const translate = useTranslation();
  const [open, setOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileOpen, setMobileOpen] = useState(false);

  const menu = menuConfig(translate);

  useEffect(() => {
    setMobileOpen(isMobileActive);
  }, [isMobileActive]);

  const handleDrawerToggle = isOpen => {
    setOpen(isOpen);
    setMobileOpen(!mobileOpen);
    onToggle(isOpen);
  };

  const handleClickSubmenu = itemIcon => {
    setSubmenuOpen(prev => (prev === itemIcon ? "" : itemIcon));
  };

  const handleMobileDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const drawerContent = (
    <>
      <DrawerHeader>{<Brand />}</DrawerHeader>
      <Divider sx={{ backgroundColor: "gray" }} />
      <List>
        {menu.aside.items.map((item, index) => {
          const hasSubmenu = item.submenu && item.submenu.length > 0;
          return (
            <div
              key={index}
              onMouseEnter={() => hasSubmenu && setSubmenuOpen(item.iconMui)}
              onMouseLeave={() => hasSubmenu && setSubmenuOpen("")}
              style={{
                margin: "1px 0",
                borderRadius: "8px",
                padding: "0px 8px",
                transition: "background 0.3s"
              }}
            >
              <ListItem
                disablePadding
                onClick={() => {
                  hasSubmenu && setSubmenuOpen(item.iconMui);
                  if (!hasSubmenu) {
                    handleDrawerToggle(false);
                    handleMobileDrawerToggle(false);
                  }
                }}
                component={hasSubmenu ? "div" : Link}
                to={hasSubmenu ? undefined : `/${item.page}`}
              >
                <ListItemButton
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)"
                    },
                    margin: "4px 0",
                    padding: "6px",
                    justifyContent: 'space-evenly'
                  }}
                >
                  <ListItemIcon style={{paddingLeft: "8px",}}>
                    <IconComponent iconName={item.iconMui} />
                  </ListItemIcon>
                  {open && !mobileOpen ? (
                    <ListItemText primary={item.title} />
                  ) : (
                    <ListItemText primary={item.title} />
                  )}
                  {hasSubmenu &&
                    (submenuOpen === item.iconMui ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    ))}
                </ListItemButton>
              </ListItem>
              {hasSubmenu && (
                <Collapse
                  in={submenuOpen === item.iconMui}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {item.submenu.map((subitem, subindex) => (
                      <ListItem
                        key={subindex}
                        component={Link}
                        to={`/${subitem.page}`}
                        sx={{ p: '4px' }}
                        disablePadding
                        onClick={() => {
                          if (hasSubmenu) {
                            setSubmenuOpen(item.iconMui);
                            handleDrawerToggle(false);
                            handleMobileDrawerToggle(false);
                          }
                        }}
                      >
                        <ListItemButton
                          sx={{
                            backgroundColor: "rgba(0, 0, 0, 0.04)",
                            borderRadius: "8px",
                            "&:hover": {
                              backgroundColor: "rgba(0, 0, 0, 0.1)"
                            },
                          }}
                        >
                          <IconComponent iconName="" />
                          <ListItemText sx={{pl:'8px'}} primary={subitem.title} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          );
        })}
      </List>
    </>
  );

  return (
    <Box style={{ display: "flex" }}>
      {isMobile ? (
        <MuiDrawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleMobileDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            width: 240,
            flexShrink: 0,
            whiteSpace: "nowrap",
            boxSizing: "border-box",
            transition: "width 0.3s",
            "& .MuiDrawer-paper": {
              width: 240,
              transition: "width 0.3s",
              overflowX: "hidden"
            }
          }}
        >
          {drawerContent}
        </MuiDrawer>
      ) : (
        <Drawer
          variant="permanent"
          open={open}
          onMouseEnter={() => handleDrawerToggle(true)}
          onMouseLeave={() => {
            handleDrawerToggle(false);
            setSubmenuOpen("");
          }}
        >
          {drawerContent}
        </Drawer>
      )}
    </Box>
  );
};

export default AsideLeft;
