import { Check } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select
} from "@mui/material";
import { useTranslation } from "_metronic/i18n/language";
import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

interface DataTest {
  [key: string]: any;
}

interface MonthlyTestResultsProps {
  data: DataTest;
  title: string;
}

const transformData = (data: DataTest, year: string) => {
  const translate = useTranslation();
  const months = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez"
  ];
  const yearData = data[year];

  if (!yearData) {
    return months.map((month, index) => ({
      name: month,
      value: 0,
      monthIndex: index + 1
    }));
  }

  return months.map((month, index) => ({
    name: month,
    value: yearData[month] || 0,
    monthIndex: index + 1
  }));
};

const MonthlyTestResults: React.FC<MonthlyTestResultsProps> = ({
  data,
  title
}) => {
  const translate = useTranslation();
  const [selectedYear, setSelectedYear] = useState<string>(
    new Date().getFullYear().toString()
  );

  const years = Object.keys(data);
  const chartData = transformData(data, selectedYear);

  const label = translate("labels_dashboard_selectYear");

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel htmlFor="yearSelect">{label} </InputLabel>
            <Select
              id="yearSelect"
              value={selectedYear}
              label={label}
              onChange={e => setSelectedYear(e.target.value)}
            >
              {years.map(year => (
                <MenuItem
                  key={year}
                  selected={year === selectedYear}
                  value={year}
                >
                  <ListItemText>{year}</ListItemText>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      />
      <Divider variant="middle" />
      <CardContent>
        <ResponsiveContainer width="100%" height={217}>
          <BarChart
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" name={translate("labels_dashboard_testsNumbers")} fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default MonthlyTestResults;
