import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

interface Resultado {
  name: string;  // Nome do gênero
  value: number; // Média de acertos
  fill: string;  // Cor da barra
}

interface BarChartGenderProps {
  data: Resultado[];
}

const BarChartGender: React.FC<BarChartGenderProps> = ({ data }) => {
  const formatValue = (value: number): string => {
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={data}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }
      }
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip formatter={formatValue} />
        <Legend />
        <Bar dataKey="value" name="Média de Acertos" fill="#8884d8" label={<LabelList dataKey="value" position="top" style={{ fontWeight: 'bold' }} formatter={formatValue}/>} barSize={80}>
          <LabelList dataKey="value" position="top" style={{ fontWeight: 'bold' }} formatter={formatValue} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartGender;
