import {
  LocaleType,
  fetchAllLocales,
  getLocales
} from "app/services/Localise/localiseApi";
import React, {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useCallback
} from "react";
import { useSelector } from "react-redux";

interface RootState {
  i18n: {
    lang: string;
  };
}

export const TranslationContext = createContext<any>(null);

export const TranslationProvider: React.FC<{ children: ReactNode }> = ({
  children
}) => {
  const locale = useSelector((state: RootState) => state.i18n.lang);

  const [translationsData, setTranslationsData] = useState<any>(null);
  const [languages, setLanguages] = useState<LocaleType[]>([]);
  const [language, setLanguage] = useState("pt-BR");

  useEffect(() => {
    const fetchLocales = async () => {
      try {
        setLanguage(locale);
        const locales = await getLocales();
        const transformedLanguages = locales?.map(language => ({
          ...language,
          lang: language.code
        }));
        if (transformedLanguages) {
          setLanguages(transformedLanguages);
        }
        const localesData = await fetchAllLocales();
        setTranslationsData(localesData);
      } catch (error) {
        console.error("Erro ao buscar os locais:", error);
      }
    };

    fetchLocales();
  }, [locale]);

  const translate = useCallback(
    (key: string, vars?: Record<string, any>) => {
      const underscoreKey = key.replace(/\./g, "_");

      // Encontra o idioma correspondente com base no código da localidade
      const { code: localeToCheck } = findLanguage(languages, language);

      if (!localeToCheck) {
        console.error(
          `Não foi possível encontrar traduções para a localidade "${localeToCheck}"`
        );
        return key;
      }

      let translations = translationsData[localeToCheck];

      // Se a tradução para a localidade específica não for encontrada, tente encontrar uma mais genérica
      if (!translations) {
        const [languageCode] = localeToCheck.split("-");
        if (languageCode !== language) {
          translations = translationsData[localeToCheck];
        }
      }

      if (
        translations &&
        (translations[underscoreKey as keyof typeof translations] ||
          translations[key as keyof typeof translations])
      ) {
        const translation =
          translations[underscoreKey as keyof typeof translations] ||
          translations[key as keyof typeof translations];

        let translatedString = translation;

        if (vars && typeof vars === "object") {
          for (const prop in vars) {
            if (vars.hasOwnProperty(prop)) {
              translatedString = translatedString.replace(
                new RegExp(`{{${prop}}}`, "g"),
                vars[prop]
              );
            }
          }
        }

        return translatedString;
      } else {
        return key;
      }
    },
    [languages, translationsData, language]
  );

  if (!translationsData) {
    return null;
  }

  return (
    <TranslationContext.Provider value={translate}>
      {children}
    </TranslationContext.Provider>
  );
};

function findLanguage(languages: any[], code: string): any | undefined {
  // Procura pelo código exato
  let exactMatch = languages.find(lang => lang.code === code);
  if (exactMatch) {
    return exactMatch;
  }

  // Procura pelos dois primeiros caracteres do código
  let partialMatch = languages.find(lang =>
    lang.code.startsWith(code.substring(0, 2))
  );
  if (partialMatch) {
    return partialMatch;
  }

  // Procura pelo primeiro idioma que tem o início do código
  partialMatch = languages.find(lang => lang.code.startsWith(code));
  if (partialMatch) {
    return partialMatch;
  }

  return undefined; // Retorna undefined se não encontrar nenhum correspondente
}

export const useTranslation = () => useContext(TranslationContext);
