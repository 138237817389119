import translate from "@noar-health/commons";
import React, { Suspense, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ErrorPage from "../errors/ErrorPage";

import { hideSpinner, showSpinner } from "../../store/actions/spinner.actions";
import store from "../../store/store";
import {
  AccountEdit,
  AccountListing,
  AnamneseForm,
  AnamneseListing,
  AnamneseUpdate,
  CampaignForm,
  CampaignsListing,
  CapsuleDetailing,
  CapsuleForm,
  CapsulesList,
  CapsulesListing,
  CapsuleUpdate,
  CategoriesListing,
  CategoryForm,
  Dashboard,
  DevicesList,
  DevicesListing,
  DeviceUpdate,
  FragranceForm,
  FragrancesListing,
  Home,
  ImportScreen,
  ManageGroup,
  Profiles,
  QuizForm,
  QuizList,
  QuizUpdate,
  ResultListing,
  UserForm,
  UserFormNew,
  UserList,
  UsersListing,Dash,Dash2
} from "./pages";
import QuizFormNew from "./pages/Quiz/QuizFormNew";
import AnamneseFormNew from "./pages/Anamnese/AnamneseFormNew";
import DashExportQuizAnswers from "./pages/Dashboard/DashExportQuizAnswer";

const spinnerActions = {
  showSpinner: () => store.dispatch(showSpinner()),
  hideSpinner: () => store.dispatch(hideSpinner())
};

interface RouteConfig {
  path: string;
  component: React.ComponentType<any>;
  exact?: boolean;
  condition?: boolean | ((auth: any) => boolean);
}

const userRoutes: RouteConfig[] = [
  { path: "/admin/dashboard", component: Dashboard },
  { path: "/admin/users/:id/update", component: UserFormNew },
  { path: "/admin/account", component: AccountListing, exact: true },
  { path: "/admin/result/:id", component: ResultListing, exact: true },
  { path: "/admin/account/edit/:id", component: AccountEdit, exact: true },
  { path: "/admin/account/create", component: AccountEdit, exact: true },
  { path: "/admin/devices", component: DevicesList, exact: true },
  {
    path: "/admin/devices/:macAddress/update",
    component: DeviceUpdate,
    exact: true
  },
  { path: "/admin/capsules", component: CapsulesList, exact: true },
  {
    path: "/admin/mygroup",
    component: ManageGroup,
    exact: true,
    condition: auth => auth.user.grouper && auth.user.usersLicense > 0
  },
  { path: "/admin/import", component: ImportScreen, exact: true }
];

const adminRoutes: RouteConfig[] = [
  { path: "/admin/dashboard", component: Dashboard },
  { path: "/admin/dsa", component: Dash },
  { path: "/admin/dsaresult", component: Dash2 },
  { path: "/admin/home", component: Dash },
  { path: "/admin/users", component: UsersListing, exact: true },
  { path: "/admin/users/create", component: UserForm },
  { path: "/admin/users/:id/update", component: UserForm },
  { path: "/admin/userlist", component: UserList },
  { path: "/admin/categories", component: CategoriesListing, exact: true },
  { path: "/admin/categories/create", component: CategoryForm },
  { path: "/admin/categories/:id/update", component: CategoryForm },
  { path: "/admin/campaigns", component: CampaignsListing, exact: true },
  { path: "/admin/campaigns/create", component: CampaignForm },
  { path: "/admin/campaigns/:id/update", component: CampaignForm },
  { path: "/admin/campaigns/:id/:clone", component: CampaignForm },
  { path: "/admin/fragrances", component: FragrancesListing, exact: true },
  { path: "/admin/fragrances/create", component: FragranceForm },
  { path: "/admin/fragrances/:id/update", component: FragranceForm },
  { path: "/admin/quiz", component: QuizList, exact: true },
  { path: "/admin/quiz/create", component: QuizForm },
  { path: "/admin/quiz/:id/update", component: QuizUpdate },
  { path: "/admin/quiz/:id/:clone", component: QuizUpdate },
  { path: "/admin/quiznew/create", component: QuizFormNew },
  { path: "/admin/quiznew/:id/update", component: QuizFormNew },
  { path: "/admin/devices", component: DevicesListing, exact: true },
  { path: "/admin/devices/:macAddress/update", component: DeviceUpdate },
  { path: "/admin/capsules", component: CapsulesListing, exact: true },
  { path: "/admin/capsules/create", component: CapsuleForm },
  {
    path: "/admin/capsules/update/:serialNumber-:fragranceId",
    component: CapsuleUpdate
  },
  {
    path: "/admin/capsules/detailing/:serialNumber-:fragranceId-:fragranceName",
    component: CapsuleDetailing
  },
  { path: "/admin/anamnese", component: AnamneseListing, exact: true },
  { path: "/admin/anamnese/create", component: AnamneseFormNew },
  { path: "/admin/anamnese/:id/update", component: AnamneseFormNew },
  { path: "/admin/account", component: AccountListing, exact: true },
  { path: "/admin/result/:id", component: ResultListing, exact: true },
  { path: "/admin/account/edit/:id", component: AccountEdit, exact: true },
  { path: "/admin/account/create", component: AccountEdit, exact: true },
  { path: "/admin/mygroup", component: ManageGroup, exact: true },
  { path: "/admin/import", component: ImportScreen, exact: true },
  {
    path: "/admin/dashboard-export-quizanswers",
    component: DashExportQuizAnswers,
    exact: true
  },
  {
    path: "/admin/perfis",
    component: Profiles,
    condition: auth => auth.user.profile.nome === "ADMIN"
  }
];

const renderRoutes = (routes: RouteConfig[], subPagesProps: any, auth: any) =>
  routes.map(
    ({ path, component: Component, exact = false, condition = true }, index) =>
      condition === true ||
      (typeof condition === "function" && condition(auth)) ? (
        <Route key={index} path={path} exact={exact}>
          <Component {...subPagesProps} />
        </Route>
      ) : null
  );

interface AdminPageProps {
  history: any;
  translate: any;
  user: any;
  lang: string;
}

const AdminPage: React.FC<AdminPageProps> = ({
  history,
  translate,
  user,
  lang
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  const subPagesProps = {
    ...spinnerActions,
    history,
    translate,
    user,
    lang,
    width
  };

  useEffect(() => {
    const resizeEvent = () => {
      if (window.innerWidth !== width) {
        setWidth(window.innerWidth);
      }
    };

    window.addEventListener("resize", resizeEvent);
    return () => window.removeEventListener("resize", resizeEvent);
  }, [width]);

  const { auth } = store.getState();
  const isUser = auth && auth.user && auth.user.profile.nome === "USER";

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Redirect
          exact
          from="/admin"
          to={isUser ? "/admin/account" : "/admin/home"}
        />
        {auth.user.profile.nome === "ADMIN"
          ? renderRoutes(adminRoutes, subPagesProps, auth)
          : renderRoutes(userRoutes, subPagesProps, auth)}
        <Route path="*" component={ErrorPage} />
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = (store: any) => ({
  translate: translate(store.i18n.lang),
  lang: store.i18n.lang,
  user: store.auth.user
});

export default connect(mapStateToProps)(AdminPage);
