import React from 'react';
import {
  ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList
} from 'recharts';

interface NumeroTeste {
  [key: number]: number;
}

interface NumeroTesteProps {
  data: NumeroTeste;
}

const BarAndLineChartTestComponent: React.FC<NumeroTesteProps> = ({ data }) => {
  // Transformar o objeto NumeroTeste em um array de objetos
  const dataArray = Object.entries(data).map(([key, value]) => ({
    key: Number(key), // assegura que a chave é numérica
    value
  }));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart width={600} height={400} data={dataArray}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="value" stroke="#ff8d12" strokeWidth={3} name="Linha de Testes" />
        <Bar dataKey="value" fill="#1e90ff" name="Número de Testes">
          <LabelList dataKey="value" position="top" style={{ fill: '#000' }} />
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default BarAndLineChartTestComponent;
