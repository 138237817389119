import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";

import store from "app/store/store";
import { AnanseApiService } from "app/services/Ananse/AnanseApiService";

import "./dash.module.css";

import {
  BarAndLineChartComponent,
  CounterCard,
  DoughnutChartComponent,
  MonthlyTestResults
} from "components";
import { useTranslation } from "_metronic/i18n/language";

interface Device {
  deviceId: string;
  macAddress: string;
  campaign?: {
    name: string;
  };
  user?: {
    nome: string;
  };
  updatedAt: string;
  owner?: {
    nome: string;
  };
  createdAt: string;
  appVersion: string;
  remainingTests: string;
}

import { colors, month, testResultInit } from "components/Dash/chartConfig";

interface Resultado {
  name: string;
  value: number;
  fill: string;
}

interface DataTest {
  [key: string]: any;
}

const Dashboard: React.FC = () => {
  const api = new AnanseApiService();
  const { auth } = store.getState();
  const translate = useTranslation();

  const [testData, setTestData] = useState<DataTest>({});
  const [sexo, setSexo] = useState([
    { name: "Group A", value: 400, fill: "#0088FE" }
  ]);
  const [numeroTeste, setNumeroTeste] = useState(0);
  const [faixaEtaria, setFaixaEtaria] = useState<Resultado[]>([
    {
      name: "00 - 10",
      value: 0,
      fill: colors.brightGreen
    },
    {
      name: "11 - 20",
      value: 0,
      fill: colors.salmonPink
    },
    {
      name: "21 - 30",
      value: 0,
      fill: colors.peach
    },
    {
      name: "31 - 40",
      value: 0,
      fill: colors.blue
    },
    {
      name: "41 - 50",
      value: 0,
      fill: colors.green
    },
    {
      name: "51 - 60",
      value: 0,
      fill: colors.pink
    },
    {
      name: "61 - 70",
      value: 0,
      fill: colors.aquamarine
    },
    {
      name: "71 - 80",
      value: 0,
      fill: colors.lemon
    }
  ]);
  const [distinctMedicos, setDistinctMedicos] = useState<number>(0);
  const [dispositivos, setDispositivos] = useState<number>(0);
  const [assinatura, setAssinatura] = useState<number>(0);
  const [pacientes, setPacientes] = useState<number>(0);
  const [resultadoTeste, setResultadoTeste] = useState<Resultado[]>(() =>
    testResultInit({
      anosmia: translate("labels_dashboard_anosmiaFunctional"),
      normosmia: translate("screenApp_results_details_normosmia"),
      hiposmia: translate("screenApp_results_details_hyposmia")
    })
  );
  useEffect(() => {
    api
      .makeHttpRequest({
        url: `/dashboard/userdashanduser?UserId=${auth.user.id}`
      })
      .then(response => {
        const lista = response.result;
        setAssinatura(lista.length);
      });
  }, []);

  useEffect(() => {
    api
      .makeHttpRequest({
        url: `/dashboard/testcabecalhodashanduser?UserId=${auth.user.id}`
      })
      .then(response => {
        const numeroPorSexo = { M: 0, F: 0 };
        const TesteData: DataTest = {};
        const lista = response.result;
        const medicosSet = new Set<string>();
        const dispositovosSet = new Set<string>();
        const pacientesSet = new Set<string>();
        setNumeroTeste(lista.length);
        const updatedFaixaEtaria = [...faixaEtaria];
        const resultadoTotal = [...resultadoTeste];
        lista.forEach((item: any) => {
          const data = new Date(item.Inicio_Teste);
          const ano = data.getFullYear();
          const mes = data.getMonth() + 1;
          if (!TesteData[ano]) {
            TesteData[ano] = {
              Jan: 0,
              Fev: 0,
              Mar: 0,
              Abr: 0,
              Mai: 0,
              Jun: 0,
              Jul: 0,
              Ago: 0,
              Set: 0,
              Out: 0,
              Nov: 0,
              Dez: 0
            };
          }
          TesteData[ano][month[mes - 1]] += 1;
          if (item.genre === "M") {
            numeroPorSexo.M += 1;
          } else {
            numeroPorSexo.F += 1;
          }
          if (item.Acertos <= 10) {
            resultadoTotal[
              resultadoTotal.findIndex(
                resultado =>
                  resultado.name ===
                  translate("labels_dashboard_anosmiaFunctional")
              )
            ].value += 1;
          } else if (item.Acertos >= 15) {
            resultadoTotal[
              resultadoTotal.findIndex(
                resultado =>
                  resultado.name ===
                  translate("screenApp_results_details_normosmia")
              )
            ].value += 1;
          } else {
            resultadoTotal[
              resultadoTotal.findIndex(
                resultado =>
                  resultado.name ===
                  translate("screenApp_results_details_hyposmia")
              )
            ].value += 1;
          }
          const rangeIndex = updatedFaixaEtaria.findIndex(
            faixa => faixa.name === item.FaixaEtaria
          );
          if (rangeIndex !== -1) {
            updatedFaixaEtaria[rangeIndex].value += 1;
          }
          if (item.Medico) {
            medicosSet.add(item.Medico);
          }
          if (item.MacAddress) {
            dispositovosSet.add(item.MacAddress);
          }
          if (item.Paciente) {
            pacientesSet.add(item.Paciente);
          }
        });

        setResultadoTeste(resultadoTotal);
        setFaixaEtaria(updatedFaixaEtaria);
        setTestData(TesteData);
        setSexo([
          {
            name: translate("screens_user_feminine"),
            value: numeroPorSexo.F,
            fill: colors.pink
          },
          {
            name: translate("screens_user_male"),
            value: numeroPorSexo.M,
            fill: colors.blue
          }
        ]);
        setDistinctMedicos(medicosSet.size);
        setDispositivos(dispositovosSet.size);
        setPacientes(pacientesSet.size);
      });
  }, []);

  const [device, setDevice] = useState<number>(0);

  useEffect(() => {
    api
      .makeHttpRequest({
        url: `/dashboard/devicedashanduser?UserId=${auth.user.id}`
      })
      .then(response => {
        const lista = response.result;
        setDevice(lista.length);
      })
      .catch(error => {
        console.error("Error fetching fragrance data:", error);
      });
  }, []);

  return (
    <Grid container spacing={2} style={{ marginBottom: 30, marginTop: 10 }}>
      {/* Primeira linha com contadores e um gráfico de rosca */}
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <CounterCard
              title={translate("labels_dashboard_bases")}
              count={device.toString()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CounterCard
              title={translate("labels_dashboard_doctors")}
              count={distinctMedicos.toString()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CounterCard
              title={translate("labels_dashboard_allTests")}
              count={numeroTeste.toString()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CounterCard
              title={translate("labels_devices_title")}
              count={dispositivos.toString()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CounterCard
              title={translate("labels_dashboard_subscriptions")}
              count={assinatura.toString()}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CounterCard
              title={translate("labels_dashboard_patients")}
              count={pacientes.toString()}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <MonthlyTestResults
          data={testData}
          title={translate("labels_dashboard_testsMonth")}
        />
      </Grid>

      {/* Segunda linha com dois gráficos de rosca e um gráfico de barra com tendência */}
      <Grid item xs={12} md={4}>
        <DoughnutChartComponent
          title={translate("labels_dashboard_testsSex")}
          data={sexo}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <DoughnutChartComponent
          title={translate("screenApp_results_screenName")}
          data={resultadoTeste}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <BarAndLineChartComponent
          title={translate("labels_dashboard_testsAgeGroup")}
          data={faixaEtaria}
        />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
