export const colors = {
  purple: "#8884d8",
  green: "#82ca9d",
  yellow: "#ffc658",
  pink: "#ff6699",
  salmonPink: "#ff6666",
  peach: "#ff9966",
  lemon: "#ffff66",
  lightLime: "#99ff66",
  brightGreen: "#66ff66",
  aquamarine: "#66ff99",
  blue: "#6699ff",
  lavender: "#9966ff"
};

type TestNamesType = {
  anosmia: string;
  normosmia: string;
  hiposmia: string;
};

export const testResultInit = ({
  anosmia,
  normosmia,
  hiposmia
}: TestNamesType) => {
  const { salmonPink, green, yellow } = colors;
  return [
    { name: anosmia, value: 0, fill: salmonPink },
    { name: normosmia, value: 0, fill: green },
    { name: hiposmia, value: 0, fill: yellow }
  ];
};

export const month = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dez"
];
